import React from 'react';
import { Helmet } from 'react-helmet';
import _ from 'lodash';

import { safePrefix } from '../utils';
import Header from './Header';
import Footer from './Footer';
import GlobalThemeContext from "../context/GlobalThemeProvider";

export default class Body extends React.Component {

    getThemeStyle = ( userTheme ) => {
        return userTheme.dark ? 'dark' : 'light';
    };

    render() {
        return (
            <>
                <Helmet>
                    <title>{_.get(this.props, 'pageContext.frontmatter.title') && _.get(this.props, 'pageContext.frontmatter.title') + ' - '}{_.get(this.props, 'pageContext.site.siteMetadata.title')}</title>
                    <meta charSet="utf-8"/>
                    <meta name="viewport" content="width=device-width, initialScale=1.0"/>
                    <meta name="description" content="Avi Rynkov. JavaScript Developer."/>
                    <meta name="keywords" content="js, javascript, node, nodejs, react, react-native, vue, redux, gatsby, web development, mobile development, software engineer"/>
                    <meta name="google" content="notranslate"/>
                    <link href="https://fonts.googleapis.com/css?family=Muli:400,400i,700,700i" rel="stylesheet"/>
                    <link rel="stylesheet" href={safePrefix('assets/css/main.css')}/>
                    <html lang="en" />
                </Helmet>
                <GlobalThemeContext.Consumer>
                    {theme => (
                        <div
                            id="page"
                            className={`site palette-${this.getThemeStyle(theme)} accent-${_.get(this.props, 'pageContext.site.siteMetadata.accent_color')}`}
                        >
                            <Header {...this.props} />
                            <main id="content" className="site-content">
                                {this.props.children}
                            </main>
                            <Footer {...this.props} />
                        </div>
                    )}
                </GlobalThemeContext.Consumer>
            </>
        );
    }
}
