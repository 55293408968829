import React from 'react';
import _ from 'lodash';

import { Link, safePrefix } from '../utils';
import GlobalThemeContext from "../context/GlobalThemeProvider";

export default class Header extends React.Component {
    render() {
        return (
            <GlobalThemeContext.Consumer>
                {theme => (
                    <header id="masthead" className="site-header outer">
                        <div className="inner">
                            <div className="site-header-inside">
                                <div className="site-branding">
                                    {_.get(this.props, 'pageContext.site.siteMetadata.header.logo_img') &&
                                    <p className="site-logo">
                                        <Link to={safePrefix('/')}>
                                            <img
                                                src={safePrefix(_.get(this.props, 'pageContext.site.siteMetadata.header.logo_img'))}
                                                alt="Logo"/>
                                        </Link>
                                    </p>
                                    }
                                    {((_.get(this.props, 'pageContext.frontmatter.template') === 'home') || (_.get(this.props, 'pageContext.frontmatter.template') === 'blog')) ?
                                        <h1 className="site-title"><Link
                                            to={safePrefix('/')}>{_.get(this.props, 'pageContext.site.siteMetadata.header.title')}</Link>
                                        </h1>
                                        :
                                        <p className="site-title"><Link
                                            to={safePrefix('/')}>{_.get(this.props, 'pageContext.site.siteMetadata.header.title')}</Link>
                                        </p>
                                    }
                                </div>
                                <div>
                                    <button className="dark-switcher" onClick={theme.toggleDark}>
                                        {theme.dark ? <span>Dark ☾</span> : <span>Light ☀</span>}
                                    </button>
                                </div>
                                {(_.get(this.props, 'pageContext.menus.main') && _.get(this.props, 'pageContext.site.siteMetadata.header.has_nav')) &&
                                <React.Fragment>
                                    <nav id="main-navigation" className="site-navigation" aria-label="Main Navigation">
                                        <div className="site-nav-inside">
                                            <button id="menu-close" className="menu-toggle"><span
                                                className="screen-reader-text">Open Menu</span><span
                                                className="icon-close" aria-hidden="true"/></button>
                                            <ul className="menu">
                                                {_.map(_.get(this.props, 'pageContext.menus.main'), ( item, item_idx ) => (
                                                    <li key={item_idx}
                                                        className={'menu-item ' + ((_.get(this.props, 'pageContext.url') === _.get(item, 'url')) ? ' current-menu-item' : '')}>
                                                        <Link
                                                            to={safePrefix(_.get(item, 'url'))}>{_.get(item, 'title')}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </nav>
                                    <button id="menu-open" className="menu-toggle"><span className="screen-reader-text">Close Menu</span><span
                                        className="icon-menu" aria-hidden="true"/></button>
                                </React.Fragment>}
                            </div>
                        </div>
                    </header>
                )}
            </GlobalThemeContext.Consumer>
        );
    }
}
